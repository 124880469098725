$().ready(function() {

	// hamburger
	$('.nav-expand').click(function(e){
		$('body').toggleClass('nav-expanded');
		e.preventDefault();
	});

	// brand select {
	$('.brand-select h2 a').on('click', function(e){
		if (!$('.brand-select').hasClass('.expanded')) {
			$('.brand-list').slideDown(200);
		} else {
			$('.brand-list').slideUp(200);
		}
		$('.brand-select').toggleClass('.expanded');
		e.preventDefault();
	});
	$('.brand-list a').on('click', function(e){
		$('.brand-list').slideUp(200);
		$('.brand-select').removeClass('.expanded');
	})

});